@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

/* Constant styles  */
$mainColor: #369dcc;
$secondColor: #0f4072;

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 16px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  direction: rtl;
}

body {
  font-family: "Tajawal", sans-serif;
}

.Toastify__toast-body {
  font-family: "Tajawal", sans-serif;
  text-align: right;
  font-weight: bold;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

a,
button {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.section-header {
  margin-bottom: 30px;
  h6 {
    font-size: 0.9rem;
  }
  h5 {
    font-size: 1.7rem;
    color: $mainColor;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    width: 40%;
    color: rgba(0, 0, 0, 0.7);
  }
}

.css-b62m3t-container {
  height: 50px;
  // border: 1px solid hsl(0, 0%, 70%);
  .css-1s2u09g-control {
    height: 100%;
    border: 1px solid #ced4da;
  }
}

.css-1pahdxg-control {
  height: 50px !important;
  margin-bottom: 10px !important;
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.form-control {
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

/* ///////////////////////////////////////////////// */

.Mysidebar {
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  width: 230px;
  position: fixed;
  top: 0;
  right: -300px;
  overflow: auto;
  padding: 10px 20px;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  .logo {
    img {
      width: 100px;
    }
  }
  .closeSideMenue {
    position: absolute;
    left: 10px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    color: $mainColor;
    border: 2px solid $mainColor;
    border-radius: 50%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .list-sidebar {
    padding: 0;
    margin: 0;
    list-style-type: none;
    .navItem {
      margin-bottom: 15px;
      .navLink {
        font-size: 1.2rem;
        color: #000;
        font-weight: 500;
        &:hover {
          color: $mainColor;
        }
      }
      .submenue {
        padding: 0;
        padding-right: 20px;
        margin: 0;
        .navItem {
          margin-bottom: 0;
          margin-top: 10px;
          &::marker {
            content: "\f104";
            color: $secondColor;
            @extend %initfontawesome;
          }
          &:hover {
            &::marker {
              color: $mainColor;
            }
            .navLink {
              color: $mainColor;
            }
          }
        }
        .navLink {
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
  .logo {
    text-align: center;
    padding-top: 50px;
    margin-bottom: 20px;
    img {
      width: 70px;
      height: auto;
    }
  }
  .login {
    display: flex;
    flex-direction: column;
    text-align: center;
    .navLink {
      font-size: 16px;
      background-color: $mainColor;
      color: #fff;
      border-radius: 5px;
      font-weight: 500;
      padding: 10px;
      // margin-right: 10px;
      margin-top: 10px;
      &:hover {
        background-color: #179c46;
      }
      &:first-child {
        background-color: #f1f1f1;
        color: $secondColor;
        &:hover {
          background-color: #dfdfdf;
        }
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    a {
      i {
        width: 25px;
        height: 25px;
        text-align: center;
        background-color: #6d6e71;
        color: #fff;
        margin-left: 5px;
        border-radius: 50%;
        font-size: 0.8rem;
        transition: 0.5s;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $mainColor;
        }
      }
    }
  }
}

/* side nav */
.openMenuActive {
  height: 100vh;
  // overflow: hidden;
  // display: none;
}

.bg-sidenavOpen {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  cursor: pointer;
  display: none;
}

.custom_navbar {
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 99;
  .shape {
    position: absolute;
    top: -60%;
    right: -7%;
    width: 200px;
  }
  .log {
    img {
      width: 95px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    padding: 29px 0;
    ul {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 27px;
        a {
          font-size: 1.5rem;
          color: $secondColor;
          font-weight: 500;
          transition: all 0.5s ease-in-out;
          display: inline-block;
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            bottom: -8px;
            width: 28px;
            background-color: $mainColor;
            position: absolute;
            right: 0;
            border-radius: 5px;
            visibility: hidden;
            opacity: 0;
            height: 0;
          }
        }
        &.active {
          a {
            color: $mainColor;
            font-weight: bold;
            &::after {
              visibility: visible;
              opacity: 1;
              height: 4px;
            }
          }
        }
      }
    }
  }
  .mobile_icon {
    display: none;
    position: absolute;
    top: 25px;
    right: 20px;
    i {
      font-size: 2.5rem;
      color: #fff;
    }
  }
}

.whats_icon{
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 999;
  a{
    display: block;
  }
  img{
    width: 70px;
  }
}

@media(max-width: 600px){
  .whats_icon{
    bottom: 20px;
    right: 20px;
    img{
      width: 60px;
    }
  }
  
}


.anuther_navbar {
  position: unset;
  .shape {
    top: -18% !important;
  }
}

/* header */
.header {
  position: relative;
  min-height: 100vh;
  .left_div {
    position: absolute;
    background-color: $mainColor;
    height: 100%;
    width: 30%;
    top: 0;
    left: 0;
    .dotted_shape {
      position: absolute;
      width: 90%;
      top: 151px;
      z-index: 9;
      right: 0;
    }
    .dotted_shape2 {
      position: absolute;
      width: 90%;
      top: 151px;
      z-index: -1;
      right: -150px;
    }
  }
  .header_content {
    height: auto;
    min-height: 100vh;
    padding-top: 150px;
    padding-bottom: 100px;
    .row {
      height: 100%;
      align-items: center;
    }
    h5 {
      font-size: 3rem;
      font-weight: bold;
      color: $secondColor;
      margin: 0;
      // margin-bottom: 5px;
    }
    h1 {
      color: $mainColor;
      font-size: 4rem;
      font-weight: bold;
      margin: 0;
      margin-bottom: 5px;
    }
    h6 {
      color: $mainColor;
      font-weight: bold;
      font-size: 3rem;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.2rem;
      margin-bottom: 30px;
      width: 80%;
      @extend %specificumberlines;
      -webkit-line-clamp: 7 !important;
    }
    a {
      padding: 13px 49px;
      background-color: $mainColor;
      color: #fff;
      border: 1px solid $mainColor;
      font-size: 1.2rem;
      display: inline-block;
      &:hover {
        background-color: #fff;
        color: $mainColor;
      }
    }
    .img_pare {
      position: relative;
      z-index: 99;
      img {
        width: 80%;
      }
    }
  }
}

.section_header {
  text-align: center;
  h5 {
    color: $mainColor;
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
  }
  p {
    width: 50%;
    margin: auto;
    font-size: 1.3rem;
    color: #7b7981;
  }
}

.portofolio {
  margin-top: 113px;
  .portofilio_cards {
    margin-top: 40px;
    .slick-list {
      // margin: 0 -25px;
    }
    .slider-item {
      padding: 15px;
      // margin: 0 25px;
    }
  }

  .slick-arrow {
    top: 100%;
  }
  .slick-next {
    right: 47%;
  }
  .slick-next:before {
    content: "\f178";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2.2px solid $mainColor;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $mainColor;
  }
  .slick-prev {
    left: 47%;
  }
  .slick-prev:before {
    content: "\f177";
    color: $mainColor;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2.2px solid $mainColor;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.blog_card {
  display: block;
  direction: rtl;
  margin-bottom: 50px;
  // padding: 15px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  // min-height: 500px;
  .card_img {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-size: 200%;
    .img_parent {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      overflow: hidden;
      left: 0;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        // transform: scale(1.08) rotate(2deg)
      }
    }
  }
  .card_body {
    padding: 21px 0px;
    transition: all 0.3s ease-in-out;
    h1 {
      font-size: 1.7rem;
      color: $secondColor;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      color: #7b7981;
      font-size: 1.2rem;
      width: 90%;
      @extend %specificumberlines;
      -webkit-line-clamp: 3 !important; /* number of lines to show */
      line-clamp: 3 !important;
    }
    a {
      color: #0f4072;
      font-size: 1.2rem;
    }
  }
  &:hover {
    box-shadow: 0 8px 22px rgba(0, 0, 0, 0.16);
    img,
    .card_img,
    .img_parent {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    img {
      transform: scale(1.04) rotate(2deg) !important;
    }
    h1 {
      color: $mainColor;
    }
    .card_body {
      padding: 21px 21px;
    }
  }
}

.alllink {
  text-align: center;
  margin-left: 15px;
  margin-top: 60px;
  a {
    background-color: $mainColor;
    color: $mainColor;
    padding: 15px 30px;
    color: #fff;
    font-weight: bold;
    // border-radius: 5px;
    display: block;
    width: fit-content;
    margin: auto;
    font-size: 1.2rem;
    border: 1px solid $mainColor;
    &:hover {
      background-color: #fff;
      color: $mainColor;
    }
  }
}

/* services */
.services {
  margin-top: 110px;
  background-position: center;
  background-size: cover;
  padding: 130px 0;
  position: relative;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background-color: $mainColor;
    opacity: 0.95;
  }
  .section-head {
    position: relative;
    z-index: 3;
    color: #fff;
    margin-bottom: 60px;
    h5 {
      font-size: 1.3rem;
    }
    p {
      font-size: 2.5rem;
      font-weight: bold;
      width: 40%;
    }
  }
  .services_cards {
    position: relative;
    z-index: 9;
  }
}
.services_cards {
  .alllink {
    text-align: center;
    a {
      background-color: #fff !important;
      font-weight: bold;
      color: $mainColor;
      border: 1px solid #fff;
      &:hover {
        background-color: transparent !important;
        color: #fff !important;
      }
    }
  }
}
.services_card {
  border: 1px solid #f5f5f5;
  padding: 42px 42px;
  text-align: center;
  margin-bottom: 50px;
  min-height: 360px;
  display: block;
  .card-img {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 120px;
    }
  }
  .card-title {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    @extend %specificumberlines;
    line-clamp: 1 !important;
    -webkit-line-clamp: 1 !important;
  }
  .card-text {
    font-size: 1.2rem;
    color: #fff;
    width: 80%;
    margin: auto;
    margin-top: 10px;
    @extend %specificumberlines;
    -webkit-line-clamp: 3 !important;
  }
}

/* blogs */
.blogs {
  .blog_card {
    box-shadow: none;
    .date {
      color: #7b7981;
    }
    &:hover {
      img {
        filter: grayscale(1);
      }
      .card_body {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

/* contact_us */
.contact_us {
  background-color: #f5f5f5;
  padding: 140px 0 90px 0;
  margin-top: 113px;
  .form_parent {
    background-color: #fff;
    padding: 68px 24px 20px 24px;
    .form_head {
      margin-bottom: 50px;
      h5 {
        color: $mainColor;
        font-size: 1.3rem;
        font-weight: 500;
      }
      h1 {
        color: $secondColor;
        font-size: 2.5rem;
        font-weight: bold;
      }
    }
    form {
      .form-group {
        font-size: 1.3rem;
        color: $secondColor;
        margin-bottom: 30px;
        label {
          margin-bottom: 10px;
          font-weight: 500;
        }
        .form-control {
          height: 48px;
          text-align: right;
        }
        span {
          color: red;
          font-size: 1rem;
        }
        textarea {
          height: 205px !important;
          resize: none;
        }
      }
      .btn_submt {
        .btn {
          background-color: $mainColor;
          color: #fff;
          padding: 13px 60px;
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 0;
          border: 1px solid $mainColor;
          &:hover {
            background-color: #fff;
            color: $mainColor;
          }
        }
      }
    }
  }
  .thmap {
    .maplocation {
      // width: 100%;
      margin: auto;
      height: 528px;
      border-radius: 30px;
      div {
        border-radius: 30px;
      }
    }
  }
}

/* Footer */
.footer {
  position: relative;
  padding: 45px 0;
  padding-bottom: 0;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background-color: $mainColor;
    opacity: 0.95;
  }
  .footer_logo {
    position: relative;
    z-index: 9;
    img {
      width: 100px;
    }
    p {
      color: #fff;
      font-size: 1.3rem;
      width: 90%;
      margin-top: 10px;
    }
  }
  .thelinks {
    position: relative;
    z-index: 9;
    h5 {
      font-size: 2rem;
      color: #fff;
      font-weight: bold;
      margin-bottom: 30px;
    }
    li {
      a {
        font-size: 1.5rem;
        color: #fff;
        margin-bottom: 10px;
        display: inline-block;
        font-weight: 500;
      }
    }
  }
  .footerinfo {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      i {
        margin-left: 5px;
        font-size: 1.2rem;
        color: #fff;
      }
      span {
        color: #fff;
        font-size: 1.2rem;
        a {
          font-size: 1.2rem;
          font-weight: 400;
          direction: ltr;
          margin: 0;
        }
      }
    }
    .follow_us {
      margin-top: 20px;
      ul {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
          margin-left: 15px;
        }
      }
    }
  }
  .copy {
    background-color: #fff;
    z-index: 9;
    position: relative;
    margin-tio: 30px;
    h5 {
      margin: 0;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;
      h5 {
        font-size: 1.2rem;
        color: $mainColor;
      }
      ul {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        li {
          margin-right: 15px;
        }
      }
    }
  }
}

#floating {
  position: fixed;
  z-index: 1;
  width: 45px;
  height: 45px;
  line-height: 70px;
  text-align: center;
  border-radius: 5px;
  bottom: 35px;
  left: 50px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  // padding-top: 5px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: all 0.5s;
  i {
    color: $mainColor;
    font-size: 25px;
    font-weight: bold;
  }
  &:hover {
    background-color: $mainColor;
    i {
      color: #fff;
    }
  }
}

.activefloating {
  opacity: 1 !important;
}

.mapmarkeronMap {
  font-size: 3rem;
  color: red;
}

/* breadcrumb */
.breadcrumb {
  position: relative;
  padding: 100px 0;
  // padding-bottom: 0;
  z-index: 9;
  justify-content: center;
  text-align: center;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background-color: $secondColor;
    opacity: 0.85;
  }
  h1 {
    color: #fff;
    z-index: 9;
    position: relative;
    margin: 0;
    font-weight: bold;
  }
}

.blog-details {
  // padding-top: 150px;
  margin-bottom: 100px;
  .blog_content {
    width: 80%;
    margin: auto;
    margin-top: 70px;
    .blog_img {
      width: 100%;
      height: 360px;
      position: relative;
      .img_parent {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
    .blog_title {
      margin-top: 10px;
      h1 {
        font-size: 2.5rem;
        color: $secondColor;
        font-weight: bold;
        margin-top: 10px;
        margin: 0;
      }
    }
    .blog_date {
      color: #6d6e71;
      font-size: 1.5rem;
    }
    .blog_pargraph {
      font-size: 1.5rem;
      margin-top: 20px;
    }
  }
}

.notfound {
  .notfound_image {
    text-align: center;
    img {
      width: 400px;
      max-width: 100%;
    }
  }
  .goto_home {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
    a {
      display: inline-block;
      background-color: $mainColor;
      color: #fff;
      padding: 10px 40px;
      font-size: 1.5rem;
      border: 1px solid $mainColor;
      &:hover {
        background-color: #fff;
        color: $mainColor;
      }
    }
  }
}

.services_page {
  .services_card {
    background-color: $secondColor !important;
  }
}

.blogdetailsserv {
  .blog_content {
    .blog_img {
      background-color: $secondColor;
      .img_parent {
        position: unset !important;
        width: 60%;
        margin: auto;
        text-align: center;
        padding: 30px 0;
        img {
          object-fit: unset !important;
          // width: 40%;
          margin: auto;
        }
      }
    }
  }
}

/* who_us_page */
.who_us_page{
  padding: 30px 0;
  p{
    font-size: 1.5rem;
  }
}

/* ///////////////////////////////////////////////// */

.portofolio,
.services {
  position: relative;
}

.services {
  .dots-loader:not(:required) {
    bottom: 100px;
  }
}

.services_page,
.allblogs_page {
  padding: 100px 0;
  position: relative;
  .dots-loader:not(:required) {
    top: 50%;
    transform-origin: 0 0;
  }
}

.contactusForm {
  position: relative;
  .loadinParent {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
  }
  .dots-loader:not(:required) {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.dots-loader:not(:required) {
  opacity: 1;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: unset;
  bottom: -50px;
  margin-left: -4px;
  margin-top: -4px;
  text-indent: -9999px;
  display: inline-block;
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 100%;
  box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
    #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  animation: dots-loader 5s infinite ease-in-out;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: 0.3s all;
  z-index: 99;
}

@keyframes dots-loader {
  0% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  8.33% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  16.67% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px 14px 0 7px, #6d7 14px 14px 0 7px,
      #4ae -14px 14px 0 7px;
  }
  25% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  33.33% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;
  }
  41.67% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  50% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  58.33% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  66.67% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px,
      #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  75% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }
  83.33% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;
  }
  91.67% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
  100% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px,
      #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
}
.modal-open {
  padding-right: 0 !important;
}
.descritptionModal {
  .btnClose {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $mainColor;
    border: 1px solid $mainColor;
    color: #fff;
    padding-top: 6px;
    border-radius: 0;
  }
  .modal-content {
    border: 0;
    padding: 20px;
    h5 {
      font-size: 1.5rem;
      margin-bottom: 15px;
      margin-top: 20px;
    }
    li {
      font-size: 1.1rem;
      margin-bottom: 2px;
    }
  }
}


/* contactModal */
.contactModal{
  .modal-content{
    border: 0;
  }
  .row{
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px !important;
    .col-sm-12{
      width: 100%;
    }
    .contact_card{
      border: 0;
      padding: 20px 0;
      border-bottom: 1px solid #eee;
    }
    ul{
      padding: 20px 0;
    }
  }
}

// /* ////////////////////////////// */

@media (max-width: 1920px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 12px;
  }
  .custom_navbar .shape {
    z-index: -1;
  }
  .services .section-head p {
    width: 50%;
  }
  .portofolio {
    .slick-next {
      right: 45%;
    }
    .slick-prev {
      left: 45%;
    }
  }
}

@media (max-width: 991px) {
  .header .header_content h5 {
    font-size: 2rem;
  }
  .header .header_content h1 {
    font-size: 2.3rem;
  }
  .header .header_content h6 {
    font-size: 2.3rem;
  }
  .section_header p,
  .services .section-head p {
    width: 100%;
  }
  .d-insmall-none {
    display: none;
  }
  .thmap {
    .maplocation {
      width: 100% !important;
    }
  }
  #floating {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .portofolio {
    .slick-arrow {
      bottom: 0;
      top: unset;
    }
    .slick-next {
      right: 7% !important;
      &::before {
        right: 0 !important;
      }
    }
    .slick-prev {
      left: 5% !important;
      &::before {
        left: 0 !important;
      }
    }
  }
  .thmap {
    margin-top: 30px;
    .maplocation {
    }
  }
}

@media (max-width: 524px) {
  .custom_navbar {
    .nav_links {
      display: none;
    }
  }
  .header .left_div {
    display: none;
  }
  .custom_navbar {
    position: relative;
    .wrapper {
      // flex-direction: row-reverse;
      justify-content: center;
      .mobile_icon {
        display: block !important;
      }
      .log {
        text-align: center;
        img {
          // width: 70px;
        }
      }
    }
  }
  .custom_navbar .shape {
    top: -68% !important;
    right: -14%;
  }
  .header .header_content {
    padding-top: 0;
    text-align: center;
    padding-bottom: 0;
  }
  .header .header_content p {
    margin: auto;
    margin-bottom: 20px;
  }
  .portofolio {
    margin-top: 50px;
  }
  .footer .copy .wrapper {
    flex-direction: column;
    h5 {
      margin-bottom: 10px;
    }
  }
  .footer .footer_logo {
    text-align: center;
    p {
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .footer .follow_us {
    text-align: center;
    ul {
      align-items: center;
      justify-content: center !important;
    }
  }
  .header,
  .header_content {
    height: auto;
    min-height: auto !important;
  }
  .services {
    margin-top: 50px;
  }
  .portofolio .custom_arrow {
    margin-top: 30px;
  }
  #floating {
    padding-top: 0;
  }
  .blog-details {
    padding-top: 0;
  }
  .blog-details .blog_content {
    width: 95%;
    margin-top: 30px;
  }
  .contact_us {
    margin-top: 50px;
  }
  #floating {
    left: 20px;
  }
  .alllink {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    left: unset !important;
    right: 0 !important;
  }
}

%specificumberlines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

%bgafter {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

%imageasbg {
  background-position: center;
  background-size: cover;
}

%initfontawesome {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

/* new Style */
.contact_card {
  text-align: center;
  border-left: 1px solid #ddd;
  i {
    width: 30px;
    height: 30px;
    background-color: $secondColor;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
  }
  a,
  p {
    display: block;
    font-size: 1.5rem;
    color: #000;
  }
}
.contact_info_head {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 20px;
}
.contact_info_ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    margin-left: 10px;
    a {
      color: #000;
      i {
        // font-size: 1.5rem;
        width: 30px;
        height: 30px;
        background-color: $secondColor;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        // margin-bottom: 10px;
      }
    }
  }
  // justify-content: center;
}

@media(max-width: 991px){
  .contact_card{
    margin-bottom: 25px;
  }
}

@media(max-width: 524px){
  .blog-details .blog_content .blog_img .img_parent img{
    object-fit: contain;
  }
  .blogdetailsserv .blog_content .blog_img .img_parent img{
    object-fit: contain !important;
  }
}